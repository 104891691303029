import API from "@/api";

export default {
  table: params => {
    return API.requestGet({
      params,
      url: "loan/order/riskTableList",
    });
  },
  setFrom: params => {
    return API.requestPost({
      url: "loan/order/riskAudit",
      data: params,
    });
  },
  getUserByJurisdiction: params => {
    return API.requestGet({
      url: "son/getUserByJurisdiction",
      params,
    });
  },
  getProductPlan: params => {
    return API.requestGet({
      url: "loan/plan/list",
      params,
    });
  },
  //增加备注
  setLabel: params => {
    return API.requestPost({
      url: "loan/order/label",
      data: params,
    });
  },
};
